import React from "react";
import "./Corporation.css";
import corporateIcon from "../../../assets/images/corporate-icon.svg";
import { Tabs, Form, Input, Space, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Avatar from "../../../assets/images/emp-avatar.png";
import CircleMinus from "../../../assets/images/circle-minus.svg";

function Corporation() {
    const { TabPane } = Tabs;

    const onChange = (key) => {
        console.log(key);
    };
    const onFinish = (values) => {
        console.log("Received values of form:", values);
    };
    return (
        <div className="component-wrapper">
            <h2 className="dashboard-title mb-3">
                <img src={corporateIcon} alt="" /> Corporation Details
            </h2>
            <div className="corporate__wrapper">
                <div className="corporate__avatarRow">
                    <div className="d-flex">
                        <div className="corporate__avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <div className="corporate__detail">
                            <h2>ITGS Gropup</h2>
                            <p className="corporate__companyName">
                                Services Company
                            </p>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="corporate__detail-left">
                                    <p>Karachi, Pakistan</p>
                                    <p className="corporate__detail-company">
                                        Company ID : ABCD-12354
                                    </p>
                                    <p>Sate of Onboarding : 1st jan 2022</p>
                                </div>
                                <div className="corporate__detail-right">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="genic__tabs">
                <Tabs defaultActiveKey="1" onChange={onChange}>
                    <TabPane tab="Profile" key="1">
                        <div className="d-flex flex-column flex-md-row">
                            <div className="profile__tabOne profile__tab">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Office
                                    Information
                                </h2>
                                <div className="corporate__detail-right profile__table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="profile__tabTwo profile__tab">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Bank
                                    Account Information
                                </h2>
                                <div className="corporate__detail-right profile__table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="Account Settings" key="2">
                        <div className="account-setting">
                            <h2 className="dashboard-title mb-3">
                                <img src={corporateIcon} alt="" /> Bank
                                Information
                            </h2>
                            <Form layout="vertical">
                                 <Row gutter={16}>
                                    <Col className="gutter-row form-group" xs={24} md={12}>
                                        <Form.Item
                                            label="Bank Name:
                                            "
                                        >
                                            <Input
                                                placeholder="Enter Bank Name"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row form-group" xs={24} md={12}>
                                        <Form.Item label="Account Type:">
                                            <Input
                                                placeholder="Enter Account type"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                 <Row gutter={16}>
                                    <Col className="gutter-row form-group" xs={24} md={12}>
                                        <Form.Item label="Account Title:">
                                            <Input
                                                placeholder="Enter Account Title"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row form-group" xs={24} md={12}>
                                        <Form.Item label="Account Number:">
                                            <Input
                                                placeholder="Enter Account Number"
                                                type="number"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                 <Row gutter={16}>
                                    <Col className="gutter-row form-group" xs={24} md={12}>
                                        <Form.Item label="IBAN:">
                                            <Input
                                                placeholder="Enter IBAN Number"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row form-group" xs={24} md={12}>
                                        <Form.Item label="Email:">
                                            <Input
                                                placeholder="Enter Email"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>

                            <div className="account__moreFields-wrapper">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Office
                                    Information
                                </h2>
                                <Form
                                    name="dynamic_form_nest_item"
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <Row gutter={16}>
                                        <Form.List name="users">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(
                                                        ({
                                                            key,
                                                            name,
                                                            ...restField
                                                        }) => (
                                                            <>
                                                                <Col className="gutter-row form-group" xs={24} md={6}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[
                                                                            name,
                                                                            "Respondant Name",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "Missing Respondant Name",
                                                                            },
                                                                        ]}
                                                                        label="Respondant Name:"
                                                                    >
                                                                        <Input placeholder="Enter Respondant Name" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className="gutter-row form-group" xs={24} md={6}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[
                                                                            name,
                                                                            "Phone",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "Missing Phone Number",
                                                                            },
                                                                        ]}
                                                                        label="Phone:"
                                                                    >
                                                                        <Input
                                                                            placeholder="Enter Phone Number"
                                                                            type="number"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className="gutter-row form-group" xs={24} md={6}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[
                                                                            name,
                                                                            "Email",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "Missing Email",
                                                                            },
                                                                        ]}
                                                                        label="Email:"
                                                                    >
                                                                        <Input placeholder="Enter Email" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className="gutter-row form-group" xs={24} md={6}>
                                                                    <img
                                                                        src={
                                                                            CircleMinus
                                                                        }
                                                                        alt=""
                                                                        onClick={() =>
                                                                            remove(name)
                                                                        }
                                                                        className="circle-minus"
                                                                    />
                                                                </Col>
                                                            </>
                                                        )
                                                    )}
                                                    <Form.Item>
                                                        <Button
                                                            onClick={() => add()}
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                            Add field
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Row>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="btn btn-primary btn-sm px-4"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>

                                </Form>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}

export default Corporation;
