import React from "react";
import corporateIcon from "../../../../assets/images/corporate-icon.svg";
import { Form, Select, Input, Checkbox, Row, Col } from "antd";

function AssignPermission() {
    return (
        <div className="component-wrapper">
            <h2 className="dashboard-title mb-3">
                <img src={corporateIcon} alt="" /> Corporation Details
            </h2>
            <div className="assign-wrapper">
                <div className="assign-inner">
                    <Form layout="vertical">
                        <Row gutter={8}>
                            <Col className="form-group" xs={24} md={12}>
                                <Form.Item label="Role" required={true}>
                                    <Select placeholder="Select Role">
                                        <Select.Option value="manager">
                                            Manager
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <div className="assign__checkbox">
                                <Checkbox>Checkbox</Checkbox>
                            </div>
                            <Col className="form-group" xs={24} md={12}>
                                <Form.Item
                                    label="Reporting Authority"
                                    required={true}
                                >
                                    <Select placeholder="Select">
                                        <Select.Option value="screening">
                                            Screening
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col className="form-group" xs={24} md={12}>
                                <Form.Item label="User" required={true}>
                                    <Select placeholder="Select User">
                                        <Select.Option value="user1">
                                            User 1
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col className="form-group" xs={24} md={12}>
                                <Form.Item label="Status" required={true}>
                                    <Select placeholder="Select">
                                        <Select.Option value="active">
                                            Active
                                        </Select.Option>
                                        <Select.Option value="in-active">
                                            In-Active
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <h2 className="dashboard-title mb-3 mt-2">
                            <img src={corporateIcon} alt="" /> Default
                            Permissions
                        </h2>
                    </Form>
                </div>
                <div className="table-wrapper table-responsive px-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Modules / Features</th>
                                <th scope="col">Read</th>
                                <th scope="col">Write</th>
                                <th scope="col">Create</th>
                                <th scope="col">Delete</th>
                                <th scope="col">Import</th>
                                <th scope="col">Export</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" className="assign-table--td">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="permission-search"
                                    />
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" className="assign-table--td">
                                    Dashboard
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" className="assign-table--td">
                                    Platform Setup - Departments
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" className="assign-table--td">
                                    Platform Setup - Roles
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="main-btn assign__btn">
                    <button className="btn btn-primary btn-sm mr-2 px-4">
                        Cancel
                    </button>
                    <button className="btn btn-primary btn-sm px-4">Save</button>
                </div>
            </div>
        </div>
    );
}

export default AssignPermission;
