import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faPencil,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Tag, Space, DatePicker, Row, Col } from "antd";
import { useState } from "react";
import { Drawer } from "antd";
import { Form, Select, Input } from "antd";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}

function onChangeSlect(date, dateString) {
    console.log(date, dateString);
}

function AllUsers() {
    // const { Option, OptGroup } = Select;
    function handleChange(value) {
        console.log(`selected ${value}`);
    }

    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");

    const changeTitle = (title) => {
        setTitle(title);
    };

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: {
                compare: (a, b) => a.name - b.name,
                multiple: 4,
            },
            filters: [
                {
                    text: "User 1",
                    value: "user 1",
                },
                {
                    text: "User 2",
                    value: "user 2",
                },
            ],
        },
        {
            title: "Reporting Authority",
            dataIndex: "reporting",
            key: "reporting",
            sorter: {
                compare: (a, b) => a.reporting - b.reporting,
                multiple: 3,
            },
        },
        {
            title: "Creation Date",
            dataIndex: "creationDate",
            key: "creationDate",
            sorter: {
                compare: (a, b) => a.creationDate - b.creationDate,
                multiple: 2,
            },
        },

        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            showDrawer();
                            changeTitle("Edit User");
                        }}
                    >
                        <div className="table-edit">
                            <FontAwesomeIcon icon={faPencil} />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            name: "User 1",
            reporting: "User 2",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
        {
            key: "2",
            name: "User 2",
            reporting: "User 3",
            creationDate: "20-Oct-2021",
            tags: ["In-Active"],
        },
        {
            key: "3",
            name: "User 3",
            reporting: "User 4",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
    ];

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>Users</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>7</h3>
                        <span>Venodrs</span>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                <button
                    className="btn btn-primary btn-sm px-4"
                    onClick={() => {
                        showDrawer();
                        changeTitle("Add User");
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} /> Add User
                </button>
            </div>
            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>

            <Drawer
                title={title}
                placement="right"
                onClose={onClose}
                visible={visible}
                width="40%"
            >
                <Form layout="vertical">
                    <Row gutter={16}>
                        <Col className="gutter-row form-group" xs={24} md={12}>
                            <Form.Item label="First Name" required={true}>
                                <Input placeholder="First name" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row form-group" xs={24} md={12}>
                            <Form.Item label="Last Name" required={true}>
                                <Input style={{width:'100%'}} placeholder="Last name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row form-group" xs={24} md={12}>
                            <Form.Item label="Email" required={true}>
                                <Input placeholder="Email" type="email" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row form-group" xs={24} md={12}>
                            <Form.Item label="Phone Number" required={true}>
                                <Input
                                    placeholder="Phone Number"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row form-group" xs={24} md={12}>
                            <Form.Item label="Department" required={true}>
                                <Select placeholder="Select Department">
                                    <Select.Option value="itgs">
                                        ITGS
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row form-group" xs={24} md={12}>
                            <Form.Item label="Role" required={true}>
                                <Select placeholder="Select role">
                                    <Select.Option value="role">
                                        Role
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row form-group" xs={24} md={12}>
                            <Form.Item label="Status" required={true}>
                                <Select placeholder="Select Status">
                                    <Select.Option value="active">
                                        Active
                                    </Select.Option>
                                    <Select.Option value="in-active">
                                        In-Active
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="form-group main-btn">
                        <button className="btn btn-primary btn-sm px-4">Save</button>
                    </div>
                </Form>
            </Drawer>
        </div>
    );
}

export default AllUsers;
