import React from "react";
import corporateIcon from "../../../assets/images/corporate-icon.svg";
import { Tabs } from "antd";
import Avatar from "../../../assets/images/emp-avatar.png";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";

function VendorProfile() {
    const { TabPane } = Tabs;

    const onChange = (key, pagination, filters, sorter, extra) => {
        console.log(key);
        console.log("params", pagination, filters, sorter, extra);
    };
    const onFinish = (values) => {
        console.log("Received values of form:", values);
    };

    const columns = [
        {
            title: "Invoices ID",
            dataIndex: "invoiceId",
            key: "invoiceId",
            sorter: {
                compare: (a, b) => a.invoiceId - b.invoiceId,
                multiple: 4,
            },
            filters: [
                {
                    text: "IN-12345",
                    value: "IN-12345",
                },
                {
                    text: "IN-12345",
                    value: "IN-12345",
                },
            ],
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            sorter: {
                compare: (a, b) => a.totalAmount - b.totalAmount,
                multiple: 3,
            },
        },
        {
            title: "Tax",
            dataIndex: "tax",
            key: "tax",
            sorter: {
                compare: (a, b) => a.tax - b.tax,
                multiple: 2,
            },
        },
        {
            title: "Issued On",
            dataIndex: "issuedOn",
            key: "issuedOn",
            sorter: {
                compare: (a, b) => a.issuedOn - b.issuedOn,
                multiple: 2,
            },
        },
        {
            title: "Approved On",
            dataIndex: "approvedOn",
            key: "approvedOn",
            sorter: {
                compare: (a, b) => a.approvedOn - b.approvedOn,
                multiple: 2,
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: {
                compare: (a, b) => a.status - b.status,
                multiple: 2,
            },
        },
    ];

    const data = [
        {
            key: "1",
            invoiceId: "IN-12345",
            totalAmount: "100,000",
            tax: "1000",
            issuedOn: "20-Oct-2021",
            approvedOn: "20-Oct-2021",
            status: "Paid",
        },
        {
            key: "2",
            invoiceId: "IN-12345",
            totalAmount: "100,000",
            tax: "1000",
            issuedOn: "20-Oct-2021",
            approvedOn: "20-Oct-2021",
            status: "Paid",
        },
        {
            key: "3",
            invoiceId: "IN-12345",
            totalAmount: "100,000",
            tax: "1000",
            issuedOn: "20-Oct-2021",
            approvedOn: "20-Oct-2021",
            status: "Paid",
        },
        {
            key: "4",
            invoiceId: "IN-12345",
            totalAmount: "100,000",
            tax: "1000",
            issuedOn: "20-Oct-2021",
            approvedOn: "20-Oct-2021",
            status: "Paid",
        },
        {
            key: "5",
            invoiceId: "IN-12345",
            totalAmount: "100,000",
            tax: "1000",
            issuedOn: "20-Oct-2021",
            approvedOn: "20-Oct-2021",
            status: "Paid",
        },
    ];

    const navigate = useNavigate();

    return (
        <div className="component-wrapper">
            <h2 className="dashboard-title mb-3">
                <img src={corporateIcon} alt="" /> Corporation Details
            </h2>
            <div className="corporate__wrapper">
                <div className="corporate__avatarRow">
                    <div className="d-flex">
                        <div className="corporate__avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <div className="corporate__detail">
                            <h2>Pro Packaging</h2>
                            <p className="corporate__companyName">
                                Packaging Carogation
                            </p>
                            <div className="">
                                <div className="corporate__detail-left">
                                    <p>Karachi, Pakistan</p>
                                    <p className="corporate__detail-company">
                                        Company ID : ABCD-12354
                                    </p>
                                    <p>Date of Onboarding : 1st jan 2022</p>
                                </div>
                                <div className="corporate__detail-right">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="corporate__detail-right">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="genic__tabs">
                <Tabs defaultActiveKey="1" onChange={onChange}>
                    <TabPane tab="Profile" key="1">
                        <div className="d-flex flex-column flex-md-row">
                            <div className="profile__tabOne profile__tab">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Office
                                    Information
                                </h2>
                                <div className="corporate__detail-right profile__table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="profile__tabTwo profile__tab">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Bank
                                    Account Information
                                </h2>
                                <div className="corporate__detail-right profile__table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Banke Name: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Account Type: </th>
                                                <td>At proin libero.</td>
                                            </tr>
                                            <tr>
                                                <th>Account Tittle: </th>
                                                <td>Faucibus eget lectus.</td>
                                            </tr>
                                            <tr>
                                                <th>Account Number: </th>
                                                <td>1234567897845121</td>
                                            </tr>
                                            <tr>
                                                <th>IBAN: </th>
                                                <td>12346789123</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="Invoices" key="2">
                        <div className="table-responsive">
                            <Table
                                onRow={(r) => ({
                                    onClick: () => navigate("/vendor-invoice"),
                                })}
                                columns={columns}
                                dataSource={data}
                                onChange={onChange}
                                showSorterTooltip={false}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}

export default VendorProfile;
