export default {
	primary: "#605bff",
	secondary: "#36F6E2",
	blackText: "#424242",
	black: "#000000",
	white: "#ffffff",
	lightText: "#9B9B9B",
	danger: "#C93030",
	bgColor: "#E5E5E5",
	borderColor: "#D2D2D2",
	headerBgColor: "#04668D",
	green: "#C0D942",
	orange: "#E25822",
	borderStroke: "#E2E2E2",
	transparent: "transparent",
	greyOne : '#828282',
	placeHolderColor: "#455A64",
	success: '#4CAF50',
	cardText: '#535353'
};
