import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

// Local imports
import { getUsers, addUser, updateUser, deleteUser } from './api/userApi';

// Components imports
import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/app-header/Header";
import Dashboard from "./components/dashboard/Dashboard";
import Department from "./components/setup/department/Department";
import Roles from "./components/setup/roles/Roles";
import AllUsers from "./components/users/all-users/AllUsers";
import Permission from "./components/users/permission/Permission";
import AllVendors from "./components/vendors/AllVendors";
import Invoices from "./components/invoices/Invoices";
import Payment from "./components/payments/Payment";
import AssignPermission from "./components/users/permission/assign-permission/AssignPermission";
import Corporation from "./components/setup/corporation-detail/Corporation";
import VendorProfile from "./components/vendors/vendor-profile/VendorProfile";
import VendorInvoice from "./components/vendors/vendor-invoice/VendorInvoice";
import PaymentDetail from "./components/payments/payment-details/PaymentDetail";
import PaymentProcss from "./components/payments/payment-process/PaymentProcess";
import PaymentConfirm from "./components/payments/payment-confirm/PaymentConfirm";
import AllInvoices from "./components/invoices/all-invoices/AllInvoices";
import Workflow from "./components/approvals/workflow/Workflow";
import WorkflowDetail from "./components/approvals/worflow-detail/WorkflowDetail";
import Login from "./components/auth/Login";

import "./style.css";
import "antd/dist/antd.css";
import { Route, Routes, useLocation } from "react-router-dom";

function App() {

    const location = useLocation();
    
    return (
        <div>
            {
                location.pathname == '/' && (
                    <Routes>
                        <Route path="/" element={<Login/>}/>
                    </Routes>
                )
            }

            {
                location.pathname != '/' && (
                    <div>
                        <Sidebar />
                        <section className="dashboard-wrapper">
                            <div className="dashboard py-4">
                                <Header />
                            </div>
                            <div className="app-main-wrapper">
                                <Routes>
                                    {/* <Route path="/" element={<Login/>}/> */}
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/departments" element={<Department />} />
                                    <Route path="/roles" element={<Roles />} />
                                    <Route
                                        path="/corporation-details"
                                        element={<Corporation />}
                                    />
                                    <Route path="/all-users" element={<AllUsers />} />
                                    <Route path="/permissions" element={<Permission />} />
                                    <Route path="/workflow" element={<Workflow />} />
                                    <Route path="/all-vendor" element={<AllVendors />} />
                                    <Route path="/invoices" element={<Invoices />} />
                                    <Route path="/payments" element={<Payment />} />

                                    <Route
                                        path="/assign-permission"
                                        element={<AssignPermission />}
                                    />
                                    <Route
                                        path="/vendor-profile"
                                        element={<VendorProfile />}
                                    />
                                    <Route
                                        path="/vendor-invoice"
                                        element={<VendorInvoice />}
                                    />
                                    <Route
                                        path="/payment-detail"
                                        element={<PaymentDetail />}
                                    />
                                    <Route
                                        path="/payment-process"
                                        element={<PaymentProcss />}
                                    />
                                    <Route
                                        path="/payment-confirmation"
                                        element={<PaymentConfirm />}
                                    />
                                    <Route
                                        path="/invoice-detail"
                                        element={<AllInvoices />}
                                    />
                                    <Route
                                        path="/workflow-detail"
                                        element={<WorkflowDetail />}
                                    />
                                </Routes>
                            </div>
                        </section>
                    </div>
                )
            }
        </div>
    );
}

export default App;
