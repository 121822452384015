import React, { useEffect, useReducer, useState } from 'react'
import './login.css';
import background from "../../assets/images/login-bg.jpg";
import {
  EyeOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons'
import colors from '../../config/colors';
import { useNavigate } from 'react-router-dom';
 

export default function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [fieldTextType, setFieldTextType] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');



  const handleSubmit = (event) => {
    console.log(formData);
    navigate("/dashboard");
  }

  const handleChange = (event) => {
    var name = event.target.name;
    if(name == 'password') {
      setPassword(event.target.value);
    } else {
      setEmail(event.target.value);
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  useEffect(() => {
    setEmail('');
    setPassword('');
  }, []);

  const togglePassword = () => {
    setFieldTextType(!fieldTextType);
  }

  return (
    <div className="login-bg" style={{ backgroundImage: `url(${background})` }}>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-12  d-flex justify-content-center align-items-center">
            <div className="login-form card bg-white shadow py-4 px-md-3">
              <div className="card-body w-100">
                <div className="d-flex justify-content-between mb-3">
                  <h2 className="title text-center my-3">Sign In</h2>
                </div>
                <form>
                  <div className="form-group">
                    <label>Email</label>
                    <input className="form-control" value={email} type="email" name="email" onChange={(e) => handleChange(e)}/>
                  </div>
                  <div className="form-group position-relative mt-3">
                    <label>Password</label>
                    <input className="form-control" value={password} type={fieldTextType ? 'text' : 'password'} name="password" onChange={(e) => handleChange(e)}/>
                    <EyeOutlined className='lock-icon' style={{ color: colors.primary }} onClick={togglePassword} />
                    {
                      fieldTextType && (
                        <EyeInvisibleOutlined className='lock-icon' style={{ color: colors.primary }} onClick={togglePassword} />
                      )
                    }
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button className="btn btn-primary btn-w btn-h r-8 btn-sm px-4" type="button" onClick={(e) => handleSubmit(e)}>Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
