import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faPencil,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Tag, Space, Checkbox } from "antd";
import { useState } from "react";
import { Drawer } from "antd";
import { Form, Select, Input } from "antd";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}
function Roles() {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");

    const changeTitle = (title) => {
        setTitle(title);
    };

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const columns = [
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            sorter: {
                compare: (a, b) => a.role - b.role,
                multiple: 4,
            },
        },
        {
            title: "Departments",
            dataIndex: "department",
            key: "department",
            sorter: {
                compare: (a, b) => a.department - b.department,
                multiple: 3,
            },
        },
        {
            title: "Reporting Authority",
            dataIndex: "reporting",
            key: "reporting",
            sorter: {
                compare: (a, b) => a.reporting - b.reporting,
                multiple: 2,
            },
        },
        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            showDrawer();
                            changeTitle("Edit Roles");
                        }}
                    >
                        <div className="table-edit">
                            <FontAwesomeIcon icon={faPencil} />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            role: "role 1",
            department: "Department 1",
            reporting: "-------",
            tags: ["Active"],
        },
        {
            key: "2",
            role: "role 2",
            department: "Department 2",
            reporting: "Role 1",
            tags: ["In-Active"],
        },
        {
            key: "3",
            role: "role 3",
            department: "Department 3",
            reporting: "Role 1",
            tags: ["Active"],
        },
    ];

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>Roles</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Vendors</span>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                <button
                    className="btn btn-primary btn-sm px-4"
                    onClick={() => {
                        showDrawer();
                        changeTitle("Add Role");
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} /> Add Role
                </button>
            </div>
            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>

            <Drawer
                title={title}
                placement="right"
                onClose={onClose}
                visible={visible}
            >
                <form className="form-row">
                    <div className="form-group ">
                        <Form.Item label="Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <Form.Item label="Reporting Authority" required={true}>
                            <Select placeholder="Select Module">
                                <Select.Option value="hrms-dashboard">
                                    hrms-dashboard
                                </Select.Option>
                                <Select.Option value="my-dashboard">
                                    my-dashboard
                                </Select.Option>
                                <Select.Option value="employee-dashboard">
                                    employee-dashboard
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <Form.Item label="Deparment" required={true}>
                            <Select placeholder="Select Module">
                                <Select.Option value="itgs">ITGS</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <Form.Item label="Status">
                            <Select placeholder="Select Status">
                                <Select.Option value="active">
                                    Active
                                </Select.Option>
                                <Select.Option value="in-active">
                                    In-Active
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <Checkbox onChange={onChange}>Checkbox</Checkbox>
                    <div className="form-group main-btn">
                        <button className="btn btn-primary btn-sm px-4">Save</button>
                    </div>
                </form>
            </Drawer>
        </div>
    );
}

export default Roles;
