import React, { useState } from "react";
import "../sidebar/Sidebar.css";
import logo from "../../assets/images/logo.svg";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
    UserOutlined,
    DashboardOutlined,
    SettingOutlined,
    BankOutlined,
    ProfileOutlined,
    SolutionOutlined,
    ClusterOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from "@ant-design/icons";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const items = [
    {
        label: "Dashboard",
        key: "1",
        icon: <DashboardOutlined />,
    },
    getItem("Setup", "sub1", <ClusterOutlined />, [
        getItem("Departments", "2"),
        getItem("Roles", "3"),
        getItem("Corporation Details", "4"),
    ]),
    getItem("Users", "sub2", <SettingOutlined />, [
        getItem("All Users", "5"),
        getItem("Permissions", "6"),
    ]),

    // getItem("Approvals", "sub3", <UserOutlined />, [
    //     getItem("Work Flows", "7"),
    // ]),
    getItem("Distributors", "sub4", <ProfileOutlined />, [
        getItem("All Distributors", "8"),
    ]),
    getItem("Invoices", "sub5", <SolutionOutlined />, [
        getItem("All Invoices", "9"),
    ]),
    getItem("Payments", "sub6", <BankOutlined />, [
        getItem("All Payments", "10"),
    ]),
]; // submenu keys of first level

const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6"];

// const Sider = () => {

function Sidebar() {
    const [openKeys, setOpenKeys] = React.useState([]);
    const navigate = useNavigate();

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const onClick = (e) => {
        if (e.key === "1") {
            showSidebar();
            navigate("/");
        } else if (e.key === "2") {
            showSidebar();
            navigate("/departments");
        } else if (e.key === "3") {
            showSidebar();
            navigate("/roles");
        } else if (e.key === "4") {
            showSidebar();
            navigate("/corporation-details");
        } else if (e.key === "5") {
            showSidebar();
            navigate("/all-users");
        } else if (e.key === "6") {
            showSidebar();
            navigate("/permissions");
        } else if (e.key === "7") {
            showSidebar();
            navigate("/workflow");
        } else if (e.key === "8") {
            showSidebar();
            navigate("/all-vendor");
        } else if (e.key === "9") {
            showSidebar();
            navigate("/invoices");
        } else if (e.key === "10") {
            showSidebar();
            navigate("/payments");
        }
        // setCurrent(e.key);
    };

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        setSidebar(!sidebar);
        console.log("not working");
    };

    return (
        <>
            <div className="sidebar-icon">
                <MenuUnfoldOutlined
                    className="icon-open"
                    onClick={showSidebar}
                />
            </div>
            <aside
               className={
                    sidebar ? "sidebar nav-menu active" : "sidebar nav-menu"
                }
            >
                <div className="menu-fold">
                    <MenuFoldOutlined
                        onClick={showSidebar}
                        className="icon-close"
                    />
                </div>

                <div className="logo-detail">
                    <div className="logo-name">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div className="sidebar-menu">
                    <Menu
                        mode="inline"
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        onClick={onClick}
                        style={{
                            width: 200,
                        }}
                        items={items}
                    >
                        {/* <Menu.Item key="mydashboard">
                        <span>My Dashboard</span>
                        <Link to="/permission" />
                    </Menu.Item> */}
                    </Menu>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;
