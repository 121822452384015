import React from "react";
import "./VendorInvoice.css";
import logo from "../../../assets/images/logo.svg";
import { Tabs } from "antd";
import corporateIcon from "../../../assets/images/corporate-icon.svg";

function VendorInvoice() {
    const { TabPane } = Tabs;

    const onChange = (key) => {
        console.log(key);
    };

    return (
        <div className="component-wrapper">
            <div className="d-flex align-item-center space-between mb-3">
                <div className="heading mb-0">
                    <h2>All Vendors</h2>
                </div>
                <button className="btn btn-primary btn-sm">
                    Download Invoice
                </button>
            </div>
            <div className="vendorInvoice__wrapper">
                <div className="vendorInvoice__logo">
                    <img src={logo} alt="" />
                </div>
                <div className="vendorInvoice__detail">
                    <h2>Invoive From Pro Packaging</h2>
                    <p className="vendorInvoice__invo-num">
                        Invoice No # IN-123456
                    </p>
                    <p>Karachi, Pakistan</p>
                    <div className="d-flex">
                        <div className="vendorInvoice-one vendorInvoice-flex">
                            <p className="font-bold">
                                Issue Date : 18-Oct-2021
                            </p>
                            <p className="issuanceAuth">
                                Issuance Authority : Muhammad Ahmed
                            </p>
                        </div>
                        <div className="vendorInvoice-two vendorInvoice-flex">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Approval Date</th>
                                        <td>__________</td>
                                    </tr>
                                    <tr>
                                        <th>Approval Authority</th>
                                        <td>Ibrahim Khan Lodhi</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="vendorInvoice-three vendorInvoice-flex">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Amount:</th>
                                        <td>
                                            <strong>40,000PKR</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td className="overdue">Overdue</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="approve-div d-flex align-item-center space-between">
                    <p className="approve-text">
                        Please approve the invoice its overdue by 15 days.
                    </p>
                    <p className="reminder">Send-Reminder</p>
                </div>
                <div className="invo__title">
                    <h2 className="dashboard-title mb-3">
                        <img src={corporateIcon} alt="" /> Corporation Details
                    </h2>
                </div>

                <div className="invoice__tabs">
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                        <TabPane tab="Details" key="1">
                            <div class="inv-table ">
                                <table class="table table-bordered table-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">Item</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Account</th>
                                            <th scope="col">Tax Rate</th>
                                            <th scope="col">Account (PKR)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Packaging Material</td>
                                            <td>Packaging Material, 12x14</td>
                                            <td>1000</td>
                                            <td>12.00</td>
                                            <td>300 - Raw Material</td>
                                            <td>Tax on Purchases (8.25%)</td>
                                            <td>600.00</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Material</td>
                                            <td>Packaging Material, 12x14</td>
                                            <td>1000</td>
                                            <td>12.00</td>
                                            <td>300 - Raw Material</td>
                                            <td>Tax on Purchases (8.25%)</td>
                                            <td>600.00</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Material</td>
                                            <td>Packaging Material, 12x14</td>
                                            <td>1000</td>
                                            <td>12.00</td>
                                            <td>300 - Raw Material</td>
                                            <td>Tax on Purchases (8.25%)</td>
                                            <td>600.00</td>
                                        </tr>
                                        <tr class="inv-total">
                                            <td colspan="5"></td>
                                            <td>Sub Total</td>
                                            <td>60,000.00</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td>
                                                Includes Tax (Raw Material
                                                8.25%)
                                            </td>
                                            <td>72.00</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td>
                                                Includes Tax (Finished Goods
                                                12%)
                                            </td>
                                            <td>7128.00</td>
                                        </tr>
                                        <tr class="inv-total">
                                            <td colspan="5"></td>
                                            <td>Total</td>
                                            <td>60,000.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </TabPane>
                        <TabPane tab="Log" key="2">
                            <div class="inv-table">
                                <table class="table table-bordered table-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">Action</th>
                                            <th scope="col">Date & Time</th>
                                            <th scope="col">User</th>
                                            <th scope="col">Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Invoice Issued</td>
                                            <td>20-Oct-2021; 18:06:25</td>
                                            <td>Muhammad Ahmed</td>
                                            <td>
                                                New invoice created with invoice
                                                number IN02156.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default VendorInvoice;
