import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faPencil,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Space, Tag } from "antd";
import { useNavigate } from "react-router-dom";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}

function Permission() {
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text) => <a>{text}</a>,
            sorter: {
                compare: (a, b) => a.name - b.name,
                multiple: 4,
            },
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            sorter: {
                compare: (a, b) => a.age - b.age,
                multiple: 3,
            },
        },
        {
            title: "Creation Date",
            dataIndex: "creationDate",
            key: "creationDate",
            sorter: {
                compare: (a, b) => a.creationDate - b.creationDate,
                multiple: 2,
            },
        },
        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a>
                        <div className="table-edit">
                            <FontAwesomeIcon
                                icon={faPencil}
                                onClick={toAssignPermission}
                            />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];
    const navigate = useNavigate();
    const toAssignPermission = () => {
        navigate("/assign-permission");
    };

    const data = [
        {
            key: "1",
            name: "User 1",
            role: "Department Head",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
        {
            key: "2",
            name: "User 2",
            role: "Senior Exective",
            creationDate: "20-Oct-2021",
            tags: ["In-Active"],
        },
        {
            key: "3",
            name: "User 3",
            role: "VP",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
    ];

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>Permissions</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Permissions</span>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                <button
                    className="btn btn-primary btn-sm px-4"
                    onClick={toAssignPermission}
                >
                    <FontAwesomeIcon icon={faPlus} /> Assign Permission
                </button>
            </div>
            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>
        </div>
    );
}

export default Permission;
