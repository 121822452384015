import React, { useState } from "react";
import "./WorkflowDetail.css";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import requester1 from "../../../assets/images/requester1.png";
import xmark from "../../../assets/images/xmark.svg";
import addFlowIcon from "../../../assets/images/add-flow.svg";
import { Button, Modal } from "antd";

function WorkflowDetail() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [products, setProducts] = useState([
        {
            level: 0,
        },
    ]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const removeLevel = (product) => {
        setProducts(
            products.filter((el) => {
                return el.level != product.level;
                // console.log(el);
            })
        );
        // console.log(products);
    };

    // const newProduct = {

    //     level: Math.floor(Math.random() * 100),
    //     // level: products.level + 1,
    // };

    const addLevel = () => {
        let productLength = products.length;
        setProducts([...products, { level: productLength }]);
    };

    const allProducts = products.map((product, i) => (
        <Draggable
            draggableId={`draggable-${i}`}
            key={`draggable-${i}`}
            index={i}
        >
            {(provided) => (
                <div
                    className="container"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div className="workflow mr-3">
                        <div className="workflow__heading mb-2">
                            <h4>Level {i + 1} Approval</h4>
                            <button onClick={() => removeLevel(product)}>
                                <img src={xmark} alt="" />
                            </button>
                        </div>
                        <div className="add-user mb-3">
                            <p>
                                Add users who are authorised to create invoices
                                and submit them for approval.
                            </p>
                            <button onClick={showModal}>Add a Requester</button>
                        </div>
                        <div className="requester d-flex">
                            <div className="requester__img">
                                <img src={requester1} alt="" />
                            </div>
                            <div className="requester__detail">
                                <p className="requester__name">
                                    Muhammad Ahmed
                                </p>
                                <p className="requester__auth">
                                    Can submit all the invoices
                                </p>
                            </div>
                            <div className="requester__del">
                                <button>
                                    <img src={xmark} alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="default-approver add-user mt-2">
                            <h4 className="mb-3">DEFAULT APPROVER</h4>
                            <button onClick={showModal} className="mb-3">
                                Add a Requester
                            </button>
                            <p>
                                A default creator is added in case there is no
                                designated creator specified.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    ));

    const dragEnd = (result) => {
        const items = [...products];
        const [orderedProducts] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, orderedProducts);

        setProducts(items);
    };

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>Work Flows</h2>
            </div>
            <div className="workflow-wrapper">
                <div className="my-container">
                    <DragDropContext onDragEnd={dragEnd}>
                        <Droppable
                            droppableId="productsSequence"
                            direction="horizontal"
                            type="column"
                        >
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="single"
                                >
                                    {allProducts}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className="add-workflow__btn">
                        <button onClick={addLevel}>
                            <img src={addFlowIcon} alt="" /> Add Level
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                title="Approval matrix for the step “Approval 1”"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width="90%"
                footer=""
            >
                <table className="table table-striped table-dark approval-table table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Approvers</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Requester</th>
                            <th scope="col">Vendor</th>
                            <th scope="col">Department</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img src={requester1} alt="" />
                                Muhammad Ahmed
                            </td>
                            <td>Any amount</td>
                            <td>Any requester</td>
                            <td>Any vendor</td>
                            <td>Any Department</td>
                        </tr>
                        <tr>
                            <td>
                                <img src={requester1} alt="" />
                                Javed Khan
                            </td>
                            <td>Any amount</td>
                            <td>Vendor requester</td>
                            <td>
                                Vendor matches <br />
                                <span>Ahmed Foods</span>
                            </td>
                            <td>Dep. matches</td>
                        </tr>
                        <tr>
                            <td>
                                <img src={requester1} alt="" />
                                Muhammad Alam
                            </td>
                            <td>Any amount</td>
                            <td>Any requester</td>
                            <td>Any vendor</td>
                            <td>
                                Any Department <br />
                                <span>Sales</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={requester1} alt="" />
                                Muhammad Ahmed
                            </td>
                            <td>Any amount</td>
                            <td>Any requester</td>
                            <td>
                                vendor matches <br />
                                <span>Ahmed Foods</span>
                            </td>
                            <td>Any Department</td>
                        </tr>
                        <tr>
                            <td>
                                <img src={requester1} alt="" />
                                Javed Khan
                            </td>
                            <td>Any amount</td>
                            <td>Any requester</td>
                            <td>Any vendor</td>
                            <td>
                                Any Department <br />
                                <span>Sales</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={requester1} alt="" />
                                Muhammad Alam
                            </td>
                            <td>Any amount</td>
                            <td>Any requester</td>
                            <td>
                                vendor matches <br />
                                <span>Ahmed Foods</span>
                            </td>
                            <td>Any Department</td>
                        </tr>
                    </tbody>
                </table>
            </Modal>
        </div>
    );
}

export default WorkflowDetail;
